import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import HeaderOrg from '../../../Headers/HeaderOrg';
import Help from '../../../../Components/Help/Help';
import ManageTitle from '../../../../Components/TitleElements/ManageTitle';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import Loader from '../../../../Components/Loader/Loader';
import Button from '../../../../Components/Button/Button';
import ToggleSwitch from '../../../../Components/ToggleSwitch/ToggleSwitch';
import { ShowToastMessage } from '../../../../helper/ShowToastMessage';
import { sortCompetitions } from '../../../../helper/SortSyllabus';

const ResultStatus = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const eventId = searchParams.get('id')
    const [event, setEvent] = useState()
    const [loading, setLoading] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [competitions, setCompetitions] = useState([])
    const [tableArr, setTableArr] = useState([])
    const [initialLoading, setInitialLoading] = useState(false)

    //get eventDetail on page load
    const getAllDetails = async () => {
        try {
            setInitialLoading(true)
            const eventUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
            const eventResponse = await serviceConsumer('GET', eventUrl)
            setEvent(eventResponse.Feis)

            const compUrl = `${process.env.REACT_APP_BASE_URL}/feis/syllabus/${eventId}`
            const compResponse = await serviceConsumer('GET', compUrl)
            let sortedCompResponse = [];
            compResponse.result.forEach(levelObj => {
                const sortedCompetitions = sortCompetitions(levelObj.competitions)
                sortedCompResponse = [...sortedCompResponse, ...sortedCompetitions]
            });
            const sortedcompWithDancers = sortedCompResponse.filter(comp => comp.dancers.length > 0)
            setCompetitions(sortedcompWithDancers)
            setInitialLoading(false)
        }
        catch (error) {
            navigate("/pageNotFound", { replace: true })
            setInitialLoading(false)
        }
    }

    useEffect(() => {
        getAllDetails()
    }, [])

    useEffect(() => {
        const tableArr = competitions?.filter((searching) => {
            if (searchInput === '') {
                return searching;
            } else if (searching?.code?.toLowerCase().includes(searchInput?.toLowerCase())) {
                return searching;
            }
        });
        setTableArr(tableArr);
    }, [competitions, searchInput]);

    //publish or unplish on toggle handler
    const changeCheckedStatusHandler = async (competition) => {
        try {
            setLoading(true)
            const publishUrl = `${process.env.REACT_APP_BASE_URL}/feis/competition/scores/publish/${competition._id}`
            const unpublishUrl = `${process.env.REACT_APP_BASE_URL}/feis/competition/scores/unpublish/${competition._id}`
            const url = competition?.results_published ? unpublishUrl : publishUrl;
            const response = await serviceConsumer("POST", url)
            const compIndex = competitions.findIndex((item) => item._id === competition._id)
            const competitionsArr = [...competitions]
            competitionsArr[compIndex].results_published = !competitionsArr[compIndex].results_published
            setCompetitions(competitionsArr)
            ShowToastMessage("success", response.message)
            setLoading(false)
        } catch (error) {
            ShowToastMessage("error", error.messages)
            setLoading(false)
        }
    }

    //view button 
    const viewResultsHandler = (competition) => {
        navigate(`/complete-results/${event?.name}?id=${event?._id}&compId=${competition?._id}`)
    }

    const editHandler = (competition) => {
        if (competition.edit) {
            localStorage.setItem("resultStatus", "edit")
            navigate(`/tabulate/${event?.name}?id=${event?._id}&compId=${competition?._id}`)
        } else {
            ShowToastMessage("error", `Results are not yet added to ${competition.code}-${competition.group} competition`)
        }
    }

    const backClickHandler = () => {
        navigate(`/organizer-manage-feis?id=${eventId}&Manage=feis`)
    }

    return (
        <>
            {loading && <Loader />}
            {initialLoading && <Loader />}
            <HeaderOrg />
            <ManageTitle eventLogo={event?.logo} eventName={event?.name} eventType={event?.eventType} eventDate={event?.feis_date} loading={initialLoading} onBackClick={backClickHandler} />
            <div className="db-body">
                {!initialLoading && <>
                    <div className="search-label pt-4">Search:</div>
                    <input
                        type="text"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        placeholder="Competition Code"
                        className="searchFeisComp"
                    />
                    <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "50px" }}>
                        <table className="table my_table competitorTable ">
                            <tbody>
                                <tr className="header-row">
                                    <td className="px-5 mx-5 w-65 br-5">Competition Code</td>
                                    <td className="mx-5 text-center w-20 br-5">Action</td>
                                    <td className="text-center w-15 br-5">Publish</td>
                                </tr>
                                {tableArr.map((competition) => {
                                    return <tr style={{ background: "#FFFFFF", fontSize: "12px" }}>
                                        <td className="px-5 mx-5 w-65 grey-30 br-5">{competition?.code} - {competition?.group}</td>
                                        <td className="mx-5 w-20 grey-30 br-5">
                                            <div className="d-flex gap-3 justify-content-center">
                                                <Button className={competition?.edit ? "filled" : "disabled"} onClick={() => viewResultsHandler(competition)}> View</Button>
                                                <Button className={competition?.edit ? "orangeFilled" : "orangeFilledDisabled"} onClick={() => editHandler(competition)}> Edit</Button>
                                            </div>
                                        </td>
                                        <td className="text-center w-15 grey-30 br-5" >
                                            <ToggleSwitch checked={competition.results_published} onChange={() => changeCheckedStatusHandler(competition)} />
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        {!loading && competitions.length === 0 && <div className="d-flex justify-content-center my-5">There are no competitors registered for this event</div>}
                    </div>
                </>}
            </div>
            <ToastContainer />
            <Help />
        </>
    )
}

export default ResultStatus