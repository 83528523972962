import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import ManageTitle from '../../../../Components/TitleElements/ManageTitle';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from '../../../../Components/Button/Button';
import { MdOutlineAttachment } from 'react-icons/md';
import HeaderOrg from '../../../Headers/HeaderOrg';
import Help from '../../../../Components/Help/Help';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import Loader from '../../../../Components/Loader/Loader';

function Email() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get('id');
  const [event, setEvent] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const ManageType = searchParams.get("Manage")
  if (!(ManageType === "grade" || ManageType === "feis"))
    navigate("/pageNotFound", { replace: true })

  //get event by eventId for title details
  const getEventDetail = async () => {
    try {
      setLoading(true);
      const url =
        ManageType.trim() == 'feis'
          ? `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
          : `${process.env.REACT_APP_BASE_URL}/grade-exam/${eventId}`;
      const response = await serviceConsumer('GET', url);
      ManageType.trim() == 'feis' ? setEvent(response.Feis) : setEvent(response.grade_exams);
      setLoading(false);
    } catch (error) {
      navigate("/pageNotFound", { replace: true })
      setLoading(false);
    }
  };
  useEffect(() => {
    getEventDetail();
  }, []);

  //Initial values for formik
  const emailInitialValues = {
    subject: '',
    message: '',
  };

  // Validation Schema for Formik
  const emailValidationSchema = Yup.object().shape({
    subject: Yup.string()
      .trim()
      .label('email')
      .required('This field is required.')
      .email()
      .max(100),
    message: Yup.string()
      .trim()
      .label('password')
      .nullable()
      .required('This field is required.'),
  });

  const emailHandler = async (values, formikProps) => {
    const formData = new FormData();
    formData.append('attachment', selectedFile);
    formData.append('message', values.message);
    formData.append('subject', values.subject);
    formData.append('eventId', eventId);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/sendmails`;
      const response = await serviceConsumer('POST', url, formData, '', 'file');
      setSuccess('Email sent successfully!');
      setTimeout(() => {
        resetFormValues(formikProps);
      }, 2000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.messages);
    }
  };

  const resetFormValues = (formikProps) => {
    const { setValues, setErrors, setTouched, setStatus } = formikProps;
    setValues(emailInitialValues);
    setErrors({});
    setTouched({});
    setStatus(null);
    setSuccess('');
    setSelectedFile('');
  };

  //handle file select
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setError('');
  };

  const changeHandler = (e, name, setFieldValue) => {
    setFieldValue(name, e.target.value);
    setError('');
  };
  return (
    <>
      {loading && <Loader />}
      <HeaderOrg />
      <ManageTitle
        eventLogo={event?.logo}
        eventName={event?.name}
        eventType={event?.eventType}
        eventDate={event?.eventType === "Grade Exam" ? event?.exam_date : event?.feis_date}
        loading={loading}
      />
      <div className="db-body">
        <Formik
          initialValues={emailInitialValues}
          // validationSchema={emailValidationSchema}
          onSubmit={emailHandler}
        >
          {({ setFieldValue, }) => {
            return (
              <Form autoComplete="off">
                <div>
                  <div className="d-flex flex-column">
                    <label className="label-head mt-3">Subject:</label>
                    <Field
                      className="input-box"
                      style={{ width: '100%' }}
                      type="subject"
                      name="subject"
                      placeholder="Subject"
                      onChange={(e) => { changeHandler(e, 'subject', setFieldValue); }}
                    />
                    <ErrorMessage className="auth-error" name="email" component="div" />
                  </div>
                  <label className="label-head mt-3">Message: </label>
                  <div className="email-msg">
                    <div className="d-flex flex-column ">
                      <Field
                        className="text-area"
                        name="message"
                        as="textarea"
                        onChange={(e) => { changeHandler(e, 'message', setFieldValue); }}
                      />
                      <ErrorMessage name="message" component="div" className="auth-error" />
                    </div>
                    <div className="d-flex gap-2">
                      <div>
                        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileSelect} />
                        <div onClick={() => document.getElementById('fileInput').click()} style={{ cursor: 'pointer', padding: '0px 5px' }}>
                          <MdOutlineAttachment />
                        </div>
                      </div>
                      {selectedFile && (
                        <div style={{ fontSize: '12px', marginTop: '5px' }}>
                          {selectedFile.name}
                        </div>
                      )}
                    </div>
                  </div>
                  {success && <div style={{ color: 'green' }}>{success}</div>}
                  {error && <div style={{ color: 'red' }}>{error}</div>}
                  <div className=" mt-3">
                    <Button className="filled" type="submit"> Send Email</Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Help />
    </>
  );
}

export default Email;
